import {
    loadHtml,
    loadCss,
    loadJs,
    loadReact,
    loadNodejs,
} from "./animationVariables"; // импорт анимации

function progressBar(
    htmlSelector,
    cssSelector,
    jsSelector,
    reactSelector,
    nodejsSelector,
    headerSelector,
    aboutSelector
) {
    // функция для заполнения прогресс-бара, когда проскроллили до него. Аргументы - 5 прогресс-бара и 2 секции до него

    const html = document.querySelector(htmlSelector), // получаем прогресс-бары
        css = document.querySelector(cssSelector),
        js = document.querySelector(jsSelector),
        react = document.querySelector(reactSelector),
        nodejs = document.querySelector(nodejsSelector),
        header = document.querySelector(headerSelector), // получаем 2 секции
        about = document.querySelector(aboutSelector);

    const heightHeader = +getComputedStyle(header).height.replace(/\D/g, ""), // получаем текущую высоту каждого элемента, отрезаем "px", переводим в число
        heightAbout = +getComputedStyle(about).height.replace(/\D/g, "");

    window.addEventListener("scroll", () => {
        // вешаем событие скролла на окно

        if (document.documentElement.scrollTop >= heightHeader + heightAbout) {
            // если проскролленная высота больше чем первые 2 секции
            html.style.animation = loadHtml; // добавляем анимацию к прогресс-барам
            css.style.animation = loadCss;
            js.style.animation = loadJs;
            react.style.animation = loadReact;
            nodejs.style.animation = loadNodejs;
        }
    });
}

export default progressBar;
