import scroll from "./modules/scroll";
import progressBar from "./modules/progressBar";
import particlesJS from "./modules/app"; // импорт настроек для библиотеки particles.min.js
import hamburger from "./modules/hamburger";
import scaleForMobile from "./modules/scaleForMobile";

window.addEventListener("DOMContentLoaded", () => {
    scroll(
        ".header__menu-link",
        "active-menu",
        ".header",
        ".about",
        ".skills",
        ".portfolio",
        ".contact"
    ); // функция для плавной прокрутки сайта и активации соответствующих кнопок в хедере
    progressBar(
        ".skills__progress-bar_html",
        ".skills__progress-bar_css",
        ".skills__progress-bar_js",
        ".skills__progress-bar_react",
        ".skills__progress-bar_nodejs",
        ".header",
        ".about"
    ); // функция для работы линий прогресса в блоке портфолио
    hamburger(".header__menu", ".header__menu-link", ".hamburger"); // функция для работы гамбургера на версии под мобилы
    scaleForMobile(); // функция для уменьшения масштаба на экранах менее 576 px
});
