function scroll(menuSelector, activClass, headerSelector, aboutSelector, skillsSelector, portfolioSelector, contactSelector) {  // функция для плавной прокрутки при нажатии на кнопки в хедере и для кнопки на главном экране

    const menuBtns = document.querySelectorAll(menuSelector);                           // берем все кнопки в хедере

    const header = document.querySelector(headerSelector),                              // берем каждую секцию и хедер
          about = document.querySelector(aboutSelector),
          skills = document.querySelector(skillsSelector),
          portfolio = document.querySelector(portfolioSelector),
          contact = document.querySelector(contactSelector);

    const heightHeader = +getComputedStyle(header).height.replace(/\D/g, ''),           // получаем текущую высоту каждого элемента, отрезаем "px", переводим в число
          heightAbout = +getComputedStyle(about).height.replace(/\D/g, ''),
          heightSkills = +getComputedStyle(skills).height.replace(/\D/g, ''),
          heightPortfolio = +getComputedStyle(portfolio).height.replace(/\D/g, ''),
          heightContact = +getComputedStyle(contact).height.replace(/\D/g, '');

    function activBtn(numberBtn) {                                                      // функция для добавления класса активности нужной кнопки, внутрь передается индекс кнопки
        menuBtns.forEach(btn => {
            btn.classList.remove(activClass);                                           // перебираем все кнопки и удаляем со всех класс активности
        });
        menuBtns[numberBtn].classList.add(activClass);                                  // добавляем класс активности нужной кнопке
    }

    window.addEventListener('scroll', () => {                                           // вешаем событие скролла на окно
        
        if ((document.documentElement.scrollTop >= (heightHeader + heightAbout + heightSkills + heightPortfolio) && document.documentElement.scrollTop < (heightHeader + heightAbout + heightSkills + heightPortfolio + heightContact)) || ((document.documentElement.scrollHeight - document.documentElement.scrollTop) == document.documentElement.clientHeight)) {
            activBtn(3);                                                                // если прокрутили до последней секции или до конца, активируем последнюю кнопку
        } else if (document.documentElement.scrollTop >= (heightHeader + heightAbout + heightSkills) && document.documentElement.scrollTop < (heightHeader + heightAbout + heightSkills + heightPortfolio)) {
            activBtn(2);                                                                // если прокрутили до секции "портфолио", активируем соответствующую кнопку
        } else if (document.documentElement.scrollTop >= (heightHeader + heightAbout) && document.documentElement.scrollTop < (heightHeader + heightAbout + heightSkills)) {
            activBtn(1);                                                                // если прокрутили до секции "мои навыки", активируем соответствующую кнопку
        } else if (document.documentElement.scrollTop >= 0 && document.documentElement.scrollTop < (heightHeader + heightAbout)) {                                
            activBtn(0);                                                                // если прокрутили до секции "обо мне" или сайт только открылся, активируем первую кнопку
        }

    });

    let links = document.querySelectorAll('[href^="#"]'),                               // берем все ссылки начинающиеся с #
        speed = 0.4;                                                                    // скорость прокрутки

    links.forEach(link => {                                                             // перебираем все ссылки
        link.addEventListener('click', function(event) {                                // навешиваем обработчик клика
            event.preventDefault();                                                     // отменяем стандартное поведение браузера

            let widthTop = document.documentElement.scrollTop,                          // значение сколько уже пролистано
                hash = this.hash,                                                       // получаем хэш текущего элемента
                toBlock = document.querySelector(hash).getBoundingClientRect().top,     // получаем верхнюю границу элемента к которому будет прокрутка
                start = null;                                                           // начальная позиция откуда скроллим

            requestAnimationFrame(step);                                                // функция анимации

            function step(time) {                                                       // функция для создания анимации
                if (start === null) {                                                   // если это первый запуск, то
                    start = time;                                                       
                }

                let progress = time - start,
                    r = (toBlock < 0 ? Math.max(widthTop - progress/speed, widthTop + toBlock) : Math.min(widthTop + progress/speed, widthTop + toBlock));  // кол-во пикселей на которое надо пролистать в течении всей анимациии и в какую сторону
                
                document.documentElement.scrollTo(0, r);                                // скроллим до рассчитанного r

                if (r != widthTop + toBlock) {                                          // если еще скролл не дошел до нужного элемента, то
                    requestAnimationFrame(step);                                        // продолжаем выполнение анимации
                } else {                                                                // если значения стали равны, значит анимация должна закончится
                    location.hash = hash;
                }
            }
        });
    });
}

export default scroll;