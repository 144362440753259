function hamburger (menuSelector, menuItemSelector, hamburgerSelector) {

    const menu = document.querySelector(menuSelector),
          menuItem = document.querySelectorAll(menuItemSelector),
          hamburger = document.querySelector(hamburgerSelector);

    hamburger.addEventListener('click', () => {
        hamburger.classList.toggle('hamburger_active');
        menu.classList.toggle('header__menu_active');
    });

    menuItem.forEach(item => {
        item.addEventListener('click', () => {
            hamburger.classList.toggle('hamburger_active');
            menu.classList.toggle('header__menu_active');
        })
    })
}

export default hamburger;