function scaleForMobile () {                                                // функция для уменьшения масштаба на экранах менее 576 px

    function result (defaultWidth, defaultScale) {

        let view = document.getElementsByName('viewport');

        view.forEach(elem => {
            elem.setAttribute('content', `width=${defaultWidth}, initial-scale=${defaultScale}`);
        });     
    }

    let clientWidth = document.documentElement.clientWidth;

    if (clientWidth < 576) {
        let scale = clientWidth / 445;
        if (scale < 1) {
            result(445, +scale.toFixed(2))
        }
    } else {
        result("device-width", "1.0")
    }
}

export default scaleForMobile; 